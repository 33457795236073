import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequest, logoutRequest } from "../auth-provider";

export const useAuthFunctions = () => {
    const msalInstance = useMsal();
    const { instance, accounts, inProgress } = msalInstance;
    const account = accounts.length > 0 && accounts[0];

    //Instance UseEffect
//   useEffect(() => {
//     instance.loginRedirect(loginRequest).catch((error) => console.log(error));
//   }, [instance]);

    const login = async () => {
        if (inProgress === InteractionStatus.None) {
            instance.loginRedirect(loginRequest).catch((error) => console.log(error));
        }
    };
    const logout = async () => {
        if (inProgress === InteractionStatus.None) {
            instance.logoutRedirect(logoutRequest).catch((error) => console.log(error));
        }
    };
    return { account, login, logout };
}