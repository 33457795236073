import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaExclamationTriangle, FaHome, FaPlus } from 'react-icons/fa';
import { getBIParams, getSingleYearDimTables } from "../services/dimension-service";
import { useEffect, useState } from "react";
import { MapTable } from "../shared/MapTable";
import { Dropdown } from "../shared/Dropdown";
import { LoadingTable } from "../shared/LoadingTable";
import { useGetToken } from "../hooks/custom-hooks";
import '../styles/DimTables.css';
import '../styles/Modules.css';
import '../styles/loading-states.css';


export const DimTableLanding = () => {
    const navigate = useNavigate();
    let { dimTable } = useParams();

    const itemsPerPage = 10;
    const [dataToView, setDataToView] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const [pageCount, setPageCount] = useState(0);

    const [searchParams] = useSearchParams();
    const year = searchParams.get('year');
    const conflicts = searchParams.get('conflicts');

    const [dimTableData, setDimTableData] = useState([]);
    const [dimTableHeaders, setDimTableHeaders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [yearDropdownOptions, setYearDropdownOptions] = useState([]);
    const [yearToView, setYearToView] = useState(year);
    const [isYearAgnostic, setIsYearAgnostic] = useState(false);
    const [canEditDimension, setCanEditDimension] = useState(true);
    const [dataToDisplay, setDataToDisplay] = useState(false);
    const [noErrorReport, setNoErrorReport] = useState(false);

    const token = useGetToken();

    useEffect(() => {
        if(token !== null) {
            const getYearDimTable = async () => {
                let response = await getSingleYearDimTables(dimTable, yearToView, true, token);
                let paramResponse = await getBIParams(token);

                console.log(response.data);

                setYearDropdownOptions(paramResponse.data.yearDropDownList);
                setDimTableData(response.data.singleYearData);     
                setDataToDisplay(response.data.singleYearData.length > 0);
                setIsYearAgnostic(response.data.isYearAgnostic);
                setCanEditDimension(response.data.canEditDimension);
                setNoErrorReport(response.data.noErrorReport);

                const currentItems = response.data.singleYearData.slice(itemOffset, endOffset);

                setDataToView(currentItems);
                setPageCount(Math.ceil(dimTableData / itemsPerPage));

                if(Object.values(response.data.singleYearData).length > 0) {
                    setDimTableHeaders(Object.keys(response.data.singleYearData[0]));
                }            
                setIsLoading(false);
            }
            getYearDimTable();
        }
    }, [token]);

    function handleYearChange(event) {
        let dropdown = event.target;
        let selectedYear = dropdown.value;

       
        setYearToView(selectedYear);
        setIsLoading(true);

        const getDimTableData = async () => {
            let rowResponse = await getSingleYearDimTables(dimTable, selectedYear, true, token);
            console.log(rowResponse);

            if(rowResponse.data) {
                setDimTableData(rowResponse.data.singleYearData);
                setDataToDisplay(rowResponse.data.singleYearData.length > 0);
                setIsYearAgnostic(rowResponse.data.isYearAgnostic);
                setCanEditDimension(rowResponse.data.canEditDimension);
                setNoErrorReport(rowResponse.data.noErrorReport);

                console.log(rowResponse.data);

                const currentItems = rowResponse.data.singleYearData.slice(itemOffset, endOffset);

                setDataToView(currentItems);
                setPageCount(Math.ceil(rowResponse.data.singleYearData / itemsPerPage));
                
                if(Object.values(rowResponse.data.singleYearData).length > 0) {
                    setDimTableHeaders(Object.keys(rowResponse.data.singleYearData[0]));
                }
                
                setIsLoading(false);
                console.log(rowResponse.data.singleYearData.length > 0);
            }
        }
        getDimTableData(); 
               
    }

    const handleBackBtn = () => {
        navigate(`/`);
    }

    function handleErrorReportClick() {

        const url = `/ErrorReport/${dimTable}?year=${yearToView}&conflicts=${conflicts}`;
        console.log(url);
        navigate(url);
    }

    function handleManagementClick() {
        navigate(`/AddRows/${dimTable}?year=${yearToView}&conflicts=${conflicts}`);
    }

    if(isLoading) {
        return (
            <div className="App Full-Page">
            <div className="header-content">
                <div className="landing-page-actions">
                    <span className='App-text back-btn' onClick={handleBackBtn}><FaHome/> Home</span>
                    <div className="action-btn-row" style={{gridGap: '20px'}}>
                        {!noErrorReport && <span className='nav-icons' title="View Errors"><FaExclamationTriangle size={30} color="lightgray"/></span>}
                        <span className='nav-icons' title="Add Rows"><FaPlus size={30} color="lightgray"/></span>
                    </div>
                </div>
            <div className="title-search-container" style={{display: 'flex', justifyContent: 'center'}}>

                <div className='loading-dimTable-title'></div>
                
                

                </div>
            </div>
            

            

            <div className="content-container" >
                <LoadingTable/>
            </div>
            
        </div>
        );
    }

    return (
        <div className="App Full-Page">
            <div className="header-content">
                    <div className="landing-page-actions">
                    <span className='App-text back-btn' onClick={handleBackBtn}><FaHome/> Home</span>
                    <div className="action-btn-row" style={{gridGap: '20px'}}>
                        {!noErrorReport &&
                            conflicts > 0 && 
                            <span className='nav-icons' onClick={handleErrorReportClick} title="View Errors"><FaExclamationTriangle size={30} color="red"/></span>
                        }
                        { !noErrorReport && conflicts == 0 && 
                            <span className='nav-icons' onClick={handleErrorReportClick} title="View Errors"><FaExclamationTriangle size={30}/></span>
                        }
                        <span className='nav-icons' onClick={handleManagementClick} title="Add Rows"><FaPlus size={30}/></span>
                    </div>
                </div>
            <div className="title-search-container">

                <div className="content-container">
                    <span className='ticket-header'>{dimTable}</span>
                    {!isYearAgnostic && 
                        <Dropdown 
                            id="dimTableLanding-dd" 
                            value={yearToView}
                            dropdownValues={yearDropdownOptions} 
                            handleDropdownChange={handleYearChange} 
                            label="Select Year to View: " 
                            className="form-fieldgroup"
                            defaultPhrase={null}
                        />
                    }
                </div>
            </div>
            </div>

            <div className="content-container">
                {dataToDisplay && 
                    <MapTable 
                        tableData={dimTableData} 
                        tableHeaders={dimTableHeaders} 
                        cellWidth="180px" 
                        dimTable={dimTable} 
                        itemsPerPage={50}
                        canEditDimension={canEditDimension}
                    />
                }

                {!dataToDisplay && 
                    <div className="no-data">There is no Data to display for this selected year.</div>
                }
            </div>
            
        </div>
        
    );
}